<script>
import TitleDescriptionSlot from '@/components/general/TitleDescriptionSlot'
import PositionInvite from '@/components/general/PositionInvite'
import GenericSlider from '@/components/general/GenericSlider'
import EmptyList from '@/components/general/EmptyList'
export default {
  name: 'homepage-business',
  components: {
    TitleDescriptionSlot,
    PositionInvite,
    EmptyList,
    GenericSlider,
    ConfirmationModal: () => import('@/components/general/ConfirmationModal')
  },
  data () {
    return {
      feed: [],
      isLoading: true,
      showModal: false
    }
  },
  created () {
    if (this.$route.query.help === 1) {
      this.$store.commit('setInviteDetails', { showMessageInvite: true, title: this.$t('global:welcome.invite'), text: this.$t('home.invite', { name: this.getUserInfo.name, companyName: this.selectedWorkspace.name }) })
      this.$router.replace({ name: 'business.home.user', params: { companyDomain: this.selectedWorkspace.subdomain, workspace: this.selectedWorkspace } })
    }
    this.getFeed()
    this.updateList()
  },
  computed: {
    username () {
      return (this.$store.getters.getUser && this.$store.getters.getUser.name) || ''
    },
    userApplicationInvites () {
      return this.$store.getters.getUserApplicationInvites
    },
    userUnreadMessages () {
      return this.$store.getters.getUserUnreadMessages
    },
    pendingRemoteInterviews () {
      return this.$store.getters.getUserMatches.filter(match => { return match.position.meeting && match.position.meeting.accepted === false }).sort((a, b) => new Date(a.position.meeting.date) - new Date(b.position.meeting.date)).map(position => position.position)
    },
    acceptedRemoteInterviews () {
      return this.$store.getters.getUserMatches.filter(match => {
        return match.position.meeting && match.position.meeting.accepted === true
      })
    },
    company () {
      return this.selectedWorkspace
    },
    workspaceId () {
      return this.selectedWorkspace && this.selectedWorkspace.lxID
    },
    lists () {
      return this.$store.getters.getWorkspaceLists[this.workspaceId] || []
    }
  },
  methods: {
    getFeed () {
      this.$store.dispatch('attemptGetFeedPlooralNews')
        .then(({ data }) => {
          this.feed = data.feed || []
        })
    },
    updateList (filter = {}) {
      this.$store.dispatch('attemptGetWorkspaceLists', { workspaceId: this.workspaceId || this.selectedWorkspace.lxID, filter })
        .finally(() => {
          this.isLoading = false
        })
    },
    refreshPendingRemoteInterviews (type) {
      this.$store.dispatch('attemptGetUserMatches').then(data => {
        this.pendingRemoteInterviews = data.filter(match => match.position.meeting && match.position.meeting.accepted === false).sort((a, b) => new Date(a.position.meeting.date) - new Date(b.position.meeting.date)).map(position => position.position)
        if (type === 'confirm') this.showModal = true
      })
    }
  },
  watch: {
    selectedWorkspace: {
      immediate: true,
      handler () {
        this.$store.dispatch('attemptGetUserApplicationInvites')
        this.$store.dispatch('attemptGetUserMatches')
        this.$store.dispatch('attemptGetUserMessages')
        this.getFeed()
      }
    }
  }
}
</script>
<template>
  <div class="homepage-business--container">
    <div class="default-home-title">
      <div>
        <img :src="company.avatar" :alt="$t(company.name)" v-if="company.avatar" />
        <h2>{{ company.name }}</h2>
      </div>
    </div>
    <title-description-slot :title="$t('home.access.news.title')">
      <v-skeleton-loader type="image" v-if="isLoading" />
      <v-carousel v-else
        height="240"
        hide-delimiter-background
        show-arrows-on-hover
        cycle
      >
        <template v-slot:prev="{ on, attrs }">
          <v-btn
              color="white"
              fab
              x-small
              light
              v-bind="attrs"
              v-on="on"
            >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-btn
              color="white"
              fab
              x-small
              light
              v-bind="attrs"
              v-on="on"
            >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </template>
        <v-carousel-item
          reverse-transition="fade-transition"
          transition="fade-transition"
          v-for="(f, i) in feed"
          :key="i"
        >
          <a :href="f.url" class="home-news-item" target="_blank">
            <span>
              <span class="home-news-title">{{ f.title }}</span>
              <span class="home-news-snipper">{{ f.snippet }}</span>
            </span>
            <img :src="f.image" />
          </a>
        </v-carousel-item>
      </v-carousel>
    </title-description-slot>

    <GenericSlider v-if="lists.length && !isLoading" title="business.home:contents" :items="lists" type="lists" v-show="lists.length" />
    <title-description-slot v-else :title="$t('business.home:contents')">
      <template v-if="isLoading">
        <div class="skeleton">
          <v-skeleton-loader v-for="i in [1, 2, 3]" :key="i" type="card-avatar, article, actions" width="296px" height="380px" />
        </div>
      </template>
      <empty-list v-else
        :title="$t('empty.state.title:no.content.title')"
        :textOne="$t('')"
        :image="'/assets/images/home-content.png'"
      ></empty-list>
    </title-description-slot>

    <title-description-slot
      v-if="userApplicationInvites.length"
      :key="userApplicationInvites.length"
      :title="$t('home.messages:invite.for.position')"
      :description="$t('home.messages:invite.for.position.description')"
    >
      <position-invite
        v-for="(invite) in userApplicationInvites"
        :key="invite.id"
        :position="invite.position"
        :company="invite.position.company"
      />
    </title-description-slot>

    <GenericSlider v-if="pendingRemoteInterviews.length" :title="$t('home.messages:invite.for.remote.interview')" :items="pendingRemoteInterviews" type="pendingRemote" @emitGenericSlider="refreshPendingRemoteInterviews"/>

    <confirmation-modal
      :maxWidth="500"
      :show="showModal"
      :btnConfirmText="false"
      @cancel="showModal = false"
      :cancelText="$t('global:close')"
      :confirmText="$t('global:candidature.page')"
      :title="$t('invite:confirmation.modal.title')"
      @confirm="$router.push({ name: 'individual.candidature.under.analysis' })"
      :description="$t('invite:confirmation.modal.description')"
    />

    <router-view/>
  </div>
</template>
<style lang="scss">
.homepage-business--container {
  .v-skeleton-loader__image {
    height: 240px;
  }
  .v-window__prev, .v-window__next {
    margin: 0 8px;
  }
  .v-carousel__controls {
    .v-btn {
      .v-icon {
        color: black;
        opacity: 1;
        &::before {
          content: "\F0766";
        }
      }
      &.v-item--active {
        .v-icon {
          &::before {
            content: "\F0765";
          }
        }
      }
    }
  }
  .home-news-item {
    display: flex;
    background-color: white;
    color: #272730;
    height: 240px;
    text-decoration: none;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    overflow: hidden;

    & > span {
      padding: 32px 40px;
      flex: 1
    }

    .home-news-title {
      display: block;
      font-size: 20px;
      font-weight: 700;
    }
    .home-news-snipper {
      display: block;
      font-size: 16px;
      font-weight: 400;
      margin-top: 4px;
    }
    img {
      max-width: 30%;
      object-fit: cover;
      object-position: left center;
      width: 100%;
      height: 100%;
    }
  }
  .content-empty-list--container{
    height: 450px;
  }
  .discovery-list--container {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    margin-bottom: 40px;

    .margin-empty {
      width: calc(100vw - 400px);
    }

    .discovery-list--item {
      display: inline-block;
      padding: 0;

      .learning-card--container {
        margin: 0;
        width: 100%;
      }
    }
  }
  .skeleton {
    display: flex;
    grid-gap: 32px;
    top: 0px;
  }
}
</style>
